<template lang="pug">
  Section.cy-cancellation-fee-section(
    required
    contains-dropdown
    :class="{ 'invalid-section': object.$error }"
    :title="$t('plan_settings.basic_settings.sections.cancellation_fee.section_name')"
  )
    .row.w-100.no-gutters
      .col
        AppDropdown.cy-cancellation-fee-rule(
          close-on-select
          order-direction="keep"
          :placeholder="$t('actions.select')"
          :class="{ invalid: object.$error }"
          :items="items"
          :value="object.$model"
          @select="object.$model = $event"
        )
      .col
</template>

<script>
  // mixins
  import withStoreModule from "@/mixins/withStoreModule"

  // cancellation fee rules store module
  import feeRulesModule from "@/config/store/price_management/plan_settings/cancellation_fee_rules"

  const feeRulesMixin = withStoreModule(feeRulesModule, {
    name: "cancellationFeeRules",
    readers: ["items"],
    actions: { fetchCancellationFeeRules: "FETCH_ITEMS" }
  })

  export default {
    props: {
      object: Object
    },

    mixins: [feeRulesMixin],

    mounted() {
      this.fetchCancellationFeeRules()
    },

    components: {
      Section: () => import("@/pages/PriceManagement/PlanSettings/BasicSettings/Section"),
      AppDropdown: () => import("@/components/elements/AppDropdown")
    }
  }
</script>
